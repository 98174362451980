const zh = {
  lang: 'zh_CN',
  operator:'操作',
  isDel:'确定要删除吗？',
  success:'成功',
  delSuccess:'删除成功！',
  addSuccess:'添加成功！',
  editSuccess:'编辑成功！',
  error:'错误',
  query:'查询',
  buxian:'不限',
  home:'首页',
  createTime: '创建时间',
  collectionManagement :'催收管理',
  paymentDays:'账期',
  bookName:'账期名称',
  startNum:'开始',
  endNum:'结束',
  startLgend:'结束必须大于开始',
  addPaymentDays:'添加账期',
  editPaymentDays:'编辑账期',
  pleaseBookName:'请输入账期名称',
  classifyName:'催计分类名称',
  addClassify:'添加催计分类',
  editClassify:'编辑催计分类',
  pleaseClassifyName:'请输入催计分类名称',
  classify:'催计分类',
  group:'催收小组',
  groupName:'小组名称',
  zhuguan:'主管',
  pleaseZhuguan:'请选择主管',
  pleaseZhangqi:'请选择账期名称',
  addgroup:'添加催收小组',
  editgroup:'编辑催收小组',
  mubiao:'目标',
  zhuguanname:'主管名称',
  cxxzcy:'查询小组成员',
  qxzzgmc:'请选择主管名称',
  qxzzqmc:'请选择账期名称',
  qsrxzmc:'请输入小组名称',
  mbbnwk:'目标不能为空',
  orderManagement:'催收订单管理',
  orderInfo:'订单催收信息',
  phone:'电话',
  mchOrderNo: '订单号',
  email: '电子邮件地址',
  userName: '姓名',
  cantidad: '应付金额',
  days: '贷款天数',
  dkje: ' 贷款金额',
  fkje: '放款金额',
  fwf : '服务费',
  lx: '利息',
  sje: '实收金额',
  fksj : '放款时间',
  lastTime: ' 最后一次还款时间',
  skzh : '收款账号',
  amount: '金额',
  plannedRepaymentAmount: '还款金额',
  channel: '渠道',
  overdueDay: '逾期天数',
  expireTime: '到期日期',
  exclusiveLinks:'还款链接',
  stats: '订单状态',
  guanxi: '关系',
  qxzguanxi: '请选择关系',
  name: '名称',
  jjlxr: '紧急联系人',
  csy: '催收员',
  addInfo: '添加记录',
  editInfo: '编辑记录',
  telTime: '拨打日期',
  rlTime: '承诺日期',
  fl: '分类',
  yg: '员工',
  beizhu: '备注',
  xzTime: '选择日期',
  qxzflmc: '请选择分类名称',
  yqddgl: '逾期订单管理',
  time: '时间范围',
  fp: '分配',
  ckrq: '存款日期',
  qxzcsymc: '请选择催收员名称',
  qgxfpdd: '请勾选需要分配的订单！',
  wbddgl: '外部订单管理',
  cpm:'产品名',
  value1: '今日申请数据',
  value2: '日期',
  value3: '新增用户',
  value4: '注册数',
  value5: '申请数',
  value6: '审核拒绝数',
  value7: '审核通过数',
  value8: '通过率',
  value9: '今日放款成功数',
  value10: '今日放款失败数',
  value11: '今日放款中数',
  value12: '总放款成功数',
  value13: '总放款失败数',
  value14: '总放款中数',
  value15: '总订单数',
  value16: '还款成功订单数',
  value17: '逾期率',
  value18: '订单数',
  value19: '未到期未还订单总数',
  value20: '未到期未还订单总金额',
  value21: '今日审核中数',
  value22: '总审核中数',
  dlm: '登录名',
  mm: '密码',
  yzm: '验证码',
  dl: '登录',
  yhgl: '用户管理',
  gjz: '关键词',
  qddh: '渠道/电话',
  yhzt: '用户状态',
  qxzyhzt: '请选择用户状态',
  ssje: '实收金额',
  drz : '待认证',
  rzz : '认证中',
  dqy : '待签约',
  shz : '审核中',
  shjj : '审核拒绝',
  fksb : '放款失败',
  jj : '结清',
  wdq : '未到期',
  dqr : '到期日',
  yq : '逾期',
  yfk : '已放款',
  yhk : '已还款',
  whk : '未还款',
  ddbj : '订单被拒',
  channel : '渠道',
  status : '状态',
  zhdlsh: '最后登录时间',
  rzxx: '认证信息',
  yhrzxx: '用户认证信息',
  yhdh:'用户电话',
  birthday:'生日',
  addressAll:'地址',
  gender:'性别',
  fatherLastName:'父姓',
  motherLastName:'母姓',
  postalCode:'邮政编码',
  fullName:'姓名',
  idNumber:'身份证号码',
  district:'地区',
  educationName:'教育程度',
  employmentName:'就业状况',
  comoanyName:'公司名称',
  comoanyPhone:'公司电话',
  workingYearName:'工作年限',
  monthlySalaryName:'月收入',
  workingDaysName:'发薪日',
  idCardPositive:'身份证正面',
  idCardReverse:'身份证反面',
  faceUrl:'人脸',
  jzz:'加载中',
  zqyq:'用户展期/逾期管理',
  type:'类型',
  qxztype:'请选择类型',
  zq:'展期',
  yq:'逾期',
  day:'天数',
  sourceMoney :'来源金额',
  yhhkgl: '用户还款管理',
  hbj :'还本金',
  hzq : '还展期',
  hwbdd : '还外部订单',
  orderNum : '还款账号',
  typeName: '还款方式',
  iden: '还款类型',
  ddgl: '订单管理',
  orderNo : '支付流水号',
  xtgl: '系统管理',
  ncdh : '昵称/登录名',
  nc:'昵称',
  role: '角色',
  qxzrole : '请选择角色',
  adduser: '添加用户',
  edituser :'编辑用户',
  gsxz : '归属小组',
  isczmm : '确定重置密码吗？',
  jsdl : '解锁登录',
  dlzh : '登录账号',
  qxzfz : '请选择分组',
  jihuo: '激活',
  suoding: '锁定',
  nan :'男',
  nv: '女',
  qsryhm: ' 请输入用户名',
  qsryhnc : '请输入用户昵称',
  qsryxdz : '请输入邮箱地址',
  qsrzqyxdz : '请输入正确的邮箱地址',
  qsrsjh: ' 请输入手机号',
  jscg : '解锁成功！',
  czcg : '重置成功！',
  czsb : '重置失败！',
  shibai: '失败',
  czcg : '操作成功！',
  grzx  : '个人中心',
  xgmm : '修改密码',
  lmm : '老密码',
  xmm : '新密码',
  qrxmm : '确认新密码',
  baoc : '保存',
  qsrlmm : '请输入老密码！',
  qsrxmm : '请输入新密码！',
  llmmbyz : '两次密码不一致！',
  xgcg : '修改成功！',
  jegl : '角色管理',
  addrole : '添加角色',
  editrole : '编辑角色',
  rolename : '角色名称',
  roleLe : '角色级别',
  data : '数据范围',
  quanbu : '全部',
  benji : '本级',
  zidy : '自定义',
  rolems : '角色描述',
  tianj : '添加',
  qxzsjfw : '请选择数据范围',
  qcxxzsjfw : '请重新选择数据范围',
  qxz : '请选择',
  xiugai : '修改',
  fpcd : '分配菜单',
  bccdcg : '保存菜单成功！',
  cpgl:'产品管理',
  addcp :'添加产品',
  editcp : '编辑产品',
  cpmc:'产品名称',
  dkqx:'贷款期限/天',
  zqfl:'展期费率',
  fxfl:'罚息费率',
  flsqfs:'费息收取方式',
  fk1:'放款时扣除服务费和利息',
  fk2:'放款时扣除服务费、到期收取利息',
  fk3:'到期收取服务费和利息',
  sytj:'使用条件/次数',
  jxks:'计息开始',
  fkdr:'放款当日（N-1）',
  fkcr:'放款次日（N）',
  tqhk:'提前还款计息方式',
  qxjx:'全周期计息',
  sjsyjz:'实际使用计息',
  qiyong :'启用',
  jinyong:'禁用',
  qq1:'请选择费息收取方式',
  qq2:'请选择计息开始',
  qq3:'请选择还款计息方式',
  qxgl:'权限管理',
  addzcd:' 添加主菜单',
  cdbt:'菜单标题',
  tup:'图标',
  qxlj:'权限路径',
  qxbs:'权限标识',
  paix:'排序',
  addzjd:'添加子节点',
  addcd :'添加菜单',
  cdmc:'菜单名称',
  qxurl:'权限URL',
  editcd:'编辑菜单',
  zhucd:'主菜单',
  zcd:'子菜单',
  gongn:'功能',
  sysparam:'系统参数配置',
  paramName:'参数名称',
  paramValue:'参数值',
  editSys:'编辑系统参数',
  edgl:'额度管理',
  tegz:'提额规则',
  jegz:'降额规则',
  added:'添加额度',
  edited:'编辑额度',
  jkcs:'借款次数',
  yqcstype:'逾期次数类型',
  yqcs:'逾期次数',
  zhycjktype:'最后一次借款逾期天数类型',
  zhycjkts:'最后一次借款逾期天数',
  dzed:'调整额度',
  qxzyqlx:'请选择逾期次数类型',
  qxzyqcslx:'请选择逾期次数类型',
  qxzzhycjktslx:'请选择最后一次借款逾期天数类型',
  v1:'等于',
  v2:'不等于',
  v3:'大于',
  v4:'大于等于',
  v5:'小于',
  v6:'小于等于',
  v7:'介于...之间',
  v8:'不介于...之间',
  v9:'包含在...内',
  v10:'不包含在...内',
  loggl:'日志管理',
  yonghum:'用户名',
  jieguo:'结果',
  liulq:'浏览器',
  shic:'时长',
  wdcsdd:'我的催收订单',
  zczds:'在催总单数',
  jrchds:'今日催回单数',
  jrchl:'今日催回率',
  zcje:'在催金额',
  chje:'催回金额',
  z:'至',
  kssj:'开始时间',
  jssj:'结束时间',
  tjcsjl:'添加催收记录',
  jbxx:'基本信息',
  ddxq:'订单详情',
  gzxx:'工作信息',
  lxr1xm:'联系人1：姓名',
  lxr2xm:'联系人2：姓名',
  lxr1dh:'联系人1：电话',
  lxr2dh:'联系人2：电话',
  lxr1gx:'联系人1：关系',
  lxr2gx:'联系人2：关系',
  sfxx:'身份信息',
  txl:'通讯录',
  gzjl:'跟踪记录',
  csjl:'催收记录',
  cjrq:'创建日期',
  qxzyggx:'请选择一个关系',
  qsrxm:'请输入姓名',
  qsrdh:'请输入电话',
  qxzrq:'请选择日期',
  qsrbz:'请输入备注',
  qxzdd:'请选择订单',
  zclstj:'在催历史统计',
  chjel:'催回金额率',
  cscgjl:'催收成功记录',
  cscgyqjl:'催收成功逾期记录',
  zctj:'在催统计',
  home1:'复借用户',
  home2:'放款数据',
  home3:'今日数据',
  home4:'历史数据',
  home5:'还款数据',
  home6:'今日到期订单数据',
  home7:'今日到期订单新增用户数据',
  home8:'今日到期订单复借用户数据',
  home9:'在贷数据',
  login1:'您切换平台环境，请退出重新登录',
  login2:'切换语言',
  login3:'确定',
  login4:'退出成功！',

  dc1:'每日数据总览',
  dc2:'今日分单量：',
  dc3:'当日回收率：',
  dc4:'当日新分回收率：',
  dc5:'昨日完成回收率：',
  dc6:'当月回收率：',
  dc7:'当日完成还款数量：',
  dc8:'昨日新分分单量：',
  dc9:'当月新分回收率：',
  dc10:'当前待催收：',
  dc11:'昨日回款数量：',
  dc12:'员工名',
  dc22:'今日分单量',
  dc13:'当前剩余待处理',
  dc14:'当日完成回收',
  dc15:'当日新分回收率',
  dc16:'当日剩余待还款',
  dc17:'当月回收率',
  dc18:'当月新分回收率',
  dc19:'昨日新分分单量',
  dc20:'昨日回款数量',
  dc21:'昨日完成回收率',

  der1:'账期配置',
  der2:'催收减免',
  der3:'添加减免',
  der4:'编辑减免',
  der5:'每单最大减免百分比',
  der6:'每月最大减免单数',
  der7:'后7天预估数',
  der8:'预估案件数',

  call0:'催回实时数据报表',
  call1:'催回',
  call2:'入催日：',
  call3:'整体',
  call4:'当日入催数：',
  call5:'新客入催数：',
  call6:'老客入催数：',
  call7:'新客目前催回数：',
  call8:'老客目前催回数：',
  call9:'新客目前催回率：',
  call10:'老客目前催回率：',
  call11:'当前总催回率：',

  loan1:'贷后整体数据页面',
  loan2:'放款日期',
  loan3:'放款数量',
  loan7:'入催案件',

  oter:'还款数量',
  oter1:'放款失败订单列表',
  oter2:'重新放款',
  oter3:'放款成功',

  txl1:'家庭',
  txl2:'普通',

  loan4:'首贷',
  loan5:'复贷',

  qux:'取消',
  cffkqr:'重复放款,请确认信息',
  fkje1:'放款金额：',
  orderNo1:'订单号： ',
  phone1:'电话： ',
  fkz1:'放款中...',

  cd1:'查看详情',
  cd2:'在催历史统计详情',

  callnum:'催收数',
  pingt:'平台',
  iscancelar:'是否结清该笔订单',
}
export default zh;