import Layout from "@/components/layout/index.vue"
import Login from "@/pages/login/index.vue"

export default[
	{
		path:"/",
		component:Layout,
		redirect:"/home",
		children:[
			{
				path:"/home",
				name:"首页",
				meta:{index:0},
				component:()=>import("@/pages/home/total.vue"),
			},
			{
				path:"/perm",
				name:"菜单管理",
				meta:{index:0},
				component:()=>import("@/pages/sys/perm/perm.vue"),
			},
			{
				path:"/role",
				name:"角色管理",
				meta:{index:0},
				component:()=>import("@/pages/sys/role/role.vue"),
			},
			{
				path:"/role/add",
				name:"添加角色",
				meta:{index:0},
				component:()=>import("@/pages/sys/role/add.vue"),
			},
			{
				path:"/role/edit",
				name:"编辑角色",
				meta:{index:0},
				component:()=>import("@/pages/sys/role/edit.vue"),
			},
			{
				path:"/user",
				name:"用户管理",
				meta:{index:0},
				component:()=>import("@/pages/sys/user/user.vue"),
			},
			{
				path:"/hogares",
				name:"公户管理",
				meta:{index:0},
				component:()=>import("@/pages/sys/user/hogares.vue"),
			},
			{
				path:"/error/403",
				name:"无权限访问",
				meta:{index:15},
				component:()=>import("@/pages/error/403.vue"),
			},
			{
				path:"/error/500",
				name:"服务器错误",
				meta:{index:16},
				component:()=>import("@/pages/error/500.vue"),
			},
			{
				path:"/error/400",
				name:"400错误",
				meta:{index:16},
				component:()=>import("@/pages/error/400.vue"),
			},
			{
				path:"/changepass",
				name:"修改登录密码",
				meta:{index:0},
				component:()=>import("@/pages/sys/user/changepass.vue"),
			},
			{
				path:"/paypass",
				name:"修改支付密码",
				meta:{index:0},
				component:()=>import("@/pages/sys/user/paypass.vue"),
			},
			{
				path:"/associate",
				name:"代收订单",
				meta:{index:0},
				component:()=>import("@/pages/order/associate.vue"),
			},
			{
				path:"/oxxopay",
				name:"oxxo代收订单",
				meta:{index:0},
				component:()=>import("@/pages/order/oxxopay.vue"),
			},
			{
				path:"/payment",
				name:"代付订单",
				meta:{index:0},
				component:()=>import("@/pages/order/payment.vue"),
			},
			{
				path:"/inform",
				name:"订单通知",
				meta:{index:0},
				component:()=>import("@/pages/order/inform.vue"),
			},
			{
				path:"/essential",
				name:"基本信息",
				meta:{index:0},
				component:()=>import("@/pages/merchant/essential.vue"),
			},
			{
				path:"/apply",
				name:"应用管理",
				meta:{index:0},
				component:()=>import("@/pages/merchant/apply/index.vue"),
			},
			{
				path:"/safe",
				name:"安全中心",
				meta:{index:0},
				component:()=>import("@/pages/merchant/safe/index.vue"),
			},
			{
				path:"/channel",
				name:"通道管理",
				meta:{index:0},
				component:()=>import("@/pages/merchant/channel/index.vue"),
			},
			{
				path:"/bill",
				name:"账单列表",
				meta:{index:0},
				component:()=>import("@/pages/bill/index.vue"),
			},
			{
				path:"/capital",
				name:"资金信息",
				meta:{index:0},
				component:()=>import("@/pages/capital/capital.vue"),
			},
			{
				path:"/withdraw",
				name:"大额提现",
				meta:{index:0},
				component:()=>import("@/pages/capital/withdraw.vue"),
			},
			{
				path:"/recharge",
				name:"充值",
				meta:{index:0},
				component:()=>import("@/pages/capital/recharge.vue"),
			},
			{
				path:"/rate",
				name:"国家信息配置",
				meta:{index:0},
				component:()=>import("@/pages/capital/rate.vue"),
			},
			{
				path:"/bank",
				name:"充值",
				meta:{index:0},
				component:()=>import("@/pages/capital/bank.vue"),
			},
			{
				path:"/auditoria",
				name:"资金审核列表",
				meta:{index:0},
				component:()=>import("@/pages/capital/auditoria.vue"),
			},
			{
				path:"/addpay",
				name:"添加服务配置",
				meta:{index:0},
				component:()=>import("@/pages/sys/user/addpay.vue"),
			},
			{
				path:"/datapay",
				name:"数据统计汇总",
				meta:{index:0},
				component:()=>import("@/pages/data/datapay.vue"),
			},
			{
				path:"/datetable",
				name:"数据表格汇总",
				meta:{index:0},
				component:()=>import("@/pages/data/datetable.vue"),
			},
			{
				path:"/always",
				name:"数据总览",
				meta:{index:0},
				component:()=>import("@/pages/data/always.vue"),
			},
			{
				path:"/monthly",
				name:"每月数据统计",
				meta:{index:0},
				component:()=>import("@/pages/data/monthly.vue"),
			},
			{
				path:"/curso",
				name:"资金流水",
				meta:{index:0},
				component:()=>import("@/pages/merchant/curso.vue"),
			},
		]
	},
	{
		path:"/login",
		name:"登录",
		component:Login,
	}
]



