<template>
    <div>
        <div class="topleft">
            <div class="logo" :style="logowid">
                <img :src="logourl" alt="" style="margin-top:-9px;">
            </div>
            <span class="closemenu" @click="changeMenu">
                <i :class="icon"></i>
            </span>
            <span class="closemenu" @click="gotohome" style="border-left:none;">
                <i class="el-icon-s-home"></i>
            </span>
        </div>
        <div class="topright">
            <em>{{userInfo.user.nickName}}（{{userInfo.user.roles[0].name}}）</em>
            <!-- <em v-if="userInfo.user.roles[0].id == 2 || userInfo.user.roles[0].id == 5">地区：{{nt}}</em> -->
            <el-dropdown style="vertical-align: middle;">
                <span class="el-dropdown-link">
                    <el-avatar size="medium" src="/img/header.jpg"></el-avatar>
                </span>
                <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item @click.native="gotochange"><span>修改登录密码</span></el-dropdown-item>
                    <el-dropdown-item @click.native="gotopay"><span>设置/修改支付密码</span></el-dropdown-item>
                    <el-dropdown-item @click.native="logOut"><span >退出登录</span></el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
        </div>
    </div>
</template>
<script>
import apiurl from "@/api/public"
import _api from "@/api/index"
import { get } from "http"
export default {
    data(){
        return{
            isclose:false,
            icon:"el-icon-s-fold",
            userInfo:{},
            logowid:"width: 200px;",
            nt: null,
        }
    },
    props:{
        logourl:{
            type:String
        },
    },
    created(){
        let userInfo2 = JSON.parse(window.localStorage.getItem('userinfo'))
        this.userInfo = userInfo2
        if (userInfo2.user.roles[0].id != 1) {
            sessionStorage.setItem("nation", userInfo2.user.nation);
            localStorage.setItem("nation", userInfo2.user.nation);
            if (userInfo2.user.nation == "in") {
                this.nt = "印度"
            }
            if (userInfo2.user.nation == "mx") {
                this.nt = "墨西哥"
            }
            if (userInfo2.user.nation == "co") {
                this.nt = "哥伦比亚"
            }
        }

        var lang = localStorage.getItem("lang")
        if (lang == null) {
            this.changeType("zh_CN");
        }
    },
    methods:{
        changeType(type) {
            this.$nextTick(() => {
                sessionStorage.setItem("locale", type);
                this.$i18n.locale = type;
                localStorage.setItem("lang", this.$t("lang"));
            });
            this.login();
        },
        changeNation(type){
            this.$nextTick(() => {
                sessionStorage.setItem("nation", type);
                localStorage.setItem("nation", type);
            });
            this.login();
        },
        login(){
            this.$alert(this.$t('login1'), this.$t('login2'), {
                confirmButtonText: this.$t('login3'),
                callback: action => {
                    this.$message({
                        type: 'info',
                        message: `action: ${ action }`
                    });
                    this.logOut()
                }
            });
        },
        gotochange(){
            this.$router.push("/changepass")
        },
        gotopay(){
            this.$router.push("/paypass")
        },
        gotohome(){
            this.$router.push("/home")
        },
        changeMenu(){
            if(!this.isclose){
                this.$emit("getchange",true)
                this.icon="el-icon-s-unfold"
                this.isclose=true
                this.logowid="width: 68px;"
            }else{
                this.$emit("getchange",false)
                this.icon="el-icon-s-fold"
                this.isclose=false
                this.logowid="width: 200px;"
            }
        },
        userCenter(){},
        logOut(){
            _api.delete(apiurl.logout,{}).then(res=>{
                this.$notify.success({
                    title: this.$t('success'),
                    message: this.$t('login4')
                });
                this.$store.commit("cleartag","")
                localStorage.removeItem("menu")
                localStorage.removeItem("num")
                localStorage.removeItem("userinfo")
                localStorage.removeItem("token")
                this.$router.push("/login")
            }).catch(err=>{
                localStorage.clear()
                this.$router.push("/login")
            })
        },
    }
}
</script>
<style scoped>
.topleft{ float: left; padding-top: 0px;}
.closemenu{ display: inline-block; padding-top: 4px; width: 50px; height:50px; cursor: pointer; text-align: center; line-height: 50px; border-left: rgba(255,255,255,0.1) solid 1px;border-right: rgba(255,255,255,0.1) solid 1px;}
.closemenu i{ font-size: 20px; color: #ffffff;}
.topright{ float: right; padding-top: 7px;}
.topright em{font-size: 14px; font-style: normal; vertical-align: middle; margin-right:15px; color: #ffffff;}
.logo{display: inline-block; text-align:center; cursor: pointer; height: 50px; position: relative; z-index: 3000; color: #ffffff; line-height: 50px; font-size: 18px;}
</style>